import { Box, Button, FilledInput, FormControl, Typography } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { post } from '../../apis';
import backgroundImage from './../../assets/img/6.jpg'
import ImageBackground from './ImageBackground';

function Podcast() {
    const [textData, setTextData] = useState('');

    const changeText = (e) => setTextData(e.target.value);
    const notify = (status) => {
        if(status) {
            toast.success("Success Notification !");
        } else {
            toast.error("Error Notification !");
        }
    }

    const fetchData = async () => {
        let formdata = new FormData();
        formdata.append("search_char", textData);
        const res = await post("/api/search", formdata);

        if (res.status === 200) notify(true);
        else notify(false)
    }

    return (
        <ImageBackground image_1={backgroundImage} image_2={backgroundImage}>
            <Box sx={{ marginTop: 10, width: { lg: '60vw', md: '60vw', sm: '100%', xs: '100%' }, }}>
                <Typography variant='body2' fontSize={30} textAlign={'center'} marginBottom={2}>Add Stream</Typography>
                <FormControl
                    variant='filled'
                    fullWidth
                    sx={{ background: '#00000080', borderRadius: '8px', borderTop: '1px solid #ffffff', }}
                >
                    <FilledInput
                        fullWidth
                        multiline
                        rows={10}
                        sx={{ borderRadius: '8px', color: 'white', fontSize: 20, lineHeight: 2, letterSpacing: 2, borderTop: '1px solid #ffffff', height: { xs: '65%', sm: '65%' } }}
                        value={textData}
                        onChange={changeText}
                        className={'blink-text'}
                        placeholder={'Please write here....'}
                    />
                </FormControl>

            </Box>
            <Box sx={{ width: { lg: '60vw', md: '60vw', sm: '100%', xs: '100%' }, background: '#00558840 !important' }}>
                <Button sx={{ fontSize: 25, width: '100%' }} color={'inherit'} onClick={fetchData}>Add Stream</Button>
            </Box>

        </ImageBackground>
    )
}

export default Podcast