import { Box } from '@mui/material'
import React from 'react'
import Podcast from '../components/homeContent/Podcast'

function AddPodcast() {
  return (
    <Box sx={{ paddingTop: { lg: '50px', xs: '100px', sm: '100px' } }}>
      <Podcast />
    </Box>
  )
}

export default AddPodcast