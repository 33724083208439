import { Box } from '@mui/material'
import React, { Suspense } from 'react'
import { RotatingLines } from 'react-loader-spinner';
import Loading from '../loading/Loading';
import Slider from '../slider/Slider'
const Cards = React.lazy(() => import('../card/Cards'));

function HomeContent() {
  return (
    <Box sx={{ paddingTop: { lg: 10, md:10, xs: 15, sm: 15 } }}>
      <Slider />
      <Box sx={{height: {lg: 'calc(100vh - 300px)', md: 'calc(100vh - 300px)', xs: 'calc(100vh - 356px)', sm: 'calc(100vh - 356px)' }, overflowY: 'scroll'}}>
        <Suspense fallback={<Loading />}>
          <Cards />
        </Suspense>
      </Box>
    </Box>
  )
}

export default HomeContent