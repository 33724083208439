import { Box } from '@mui/material'
import React from 'react'
import NewsLetterSignup from '../components/homeContent/NewsLetterSignup'

function NewsletterSignUp() {
  return (
    <Box sx={{ paddingTop: { lg: '50px', xs: '100px', sm: '100px' } }}>
      <NewsLetterSignup />
    </Box>
  )
}

export default NewsletterSignUp