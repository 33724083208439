import React from "react";
import { Box } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

function Loading() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
            />
        </Box>
    )
}

export default Loading;