import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { About, AddPodcast, Home, NewsletterSignUp } from './pages';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import Header from './chatgpt/header';
import Header from './components/header';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/findchannel" element={<Home />} />
        <Route path="/add" element={<AddPodcast />} />
        <Route path="/signup" element={<NewsletterSignUp />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        theme="dark"
      />
    </div>
  );
}

export default App;
