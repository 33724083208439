import React from 'react'
import { Box, Button, Typography, Avatar, styled } from '@mui/material'
import backgroundImage from './../../assets/img/podcast.jpg'
import backgroundImage1 from './../../assets/img/8.jpg'
import { CoffeeMaker, LinkedIn, } from '@mui/icons-material';
import ImageBackground from './ImageBackground';
import avatar from './../../assets/img/avatar.png'

const LinkedinButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#0055ff80'),
    backgroundColor: '#0055ff80',
    '&:hover': {
      backgroundColor: '#0055ff80',
    },
}));

const CoffeeButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#7a5a0080'),
    backgroundColor: '#7a5a0080',
    '&:hover': {
      backgroundColor: '#7a5a0080',
    },
}));

function AboutContent() {
    const goToLink = (link) => {
        if (link === 'linkedin')
        document.location.href = "https://www.linkedin.com/in/ryancdeeds/";
        else document.location.href = 'https://www.buymeacoffee.com/YCBhZ6v';

    }
    return (
        <ImageBackground image_1={backgroundImage} image_2={backgroundImage1}>
            <Box
                sx={{ width: { xs: '100%', sm: '100%', md: '60%', lg: '60%' }, height: { xs: '100%', sm: '100%', md: '80%', lg: '80%' }, backgroundColor: '#00000080', borderRadius: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
                <Typography variant='h3' sx={{ marginBottom: 3, letterSpacing: 10 }}>About</Typography>
                <Typography variant='h4' sx={{ marginBottom: 2, marginLeft: { xs: 3, sm: 3 }, marginRight: { xs: 3, sm: 3 }, textAlign: 'center', letterSpacing: 5 }}>What is this?</Typography>
                <Typography variant='h9' sx={{ marginBottom: 2, marginLeft: { xs: 3, sm: 3 }, marginRight: { xs: 3, sm: 3 }, textAlign: 'center', }}>A place for all insurance videos and podcasts. We make it easy to stay up to date on all that is going on in the industry.</Typography>
                <Typography variant='h9' sx={{ marginBottom: 2, marginLeft: { xs: 3, sm: 3 }, marginRight: { xs: 3, sm: 3 }, textAlign: 'center', }}>We also use AI to help distill down who the podcast is best suited for and what the main take aways are.</Typography>
                <Typography variant='h5' sx={{ marginBottom: 2, marginLeft: { xs: 3, sm: 3 }, marginRight: { xs: 3, sm: 3 }, textAlign: 'center', }}>Who did this?</Typography>
                <Avatar 
                    alt='Ryan Deeds'
                    src={avatar}
                    sx={{width: 120, height: 120,}}
                />
                <Box sx={{display:'flex', paddingTop: 5, justifyContent: 'space-between'}}>
                    <LinkedinButton variant='contained' startIcon={<LinkedIn />} style={{width: 180}} onClick={()=>goToLink('linkedin')} >Linkedin</LinkedinButton>
                    <CoffeeButton variant='contained' startIcon={<CoffeeMaker />} style={{width: 180}} onClick={()=>goToLink('coffee')}>Buy me a coffee</CoffeeButton>
                </Box>
                <Typography variant='h7' sx={{ marginBottom: 1, marginLeft: { xs: 3, sm: 3 }, marginRight: { xs: 3, sm: 3 }, textAlign: 'center', paddingTop: 5 }}>Ryan Deeds created this website to help insurance industry professionals keep up to date with all the content being published today.</Typography>

            </Box>
        </ImageBackground>
    )
}

export default AboutContent