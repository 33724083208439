import React from 'react'
import { Box, Button, Typography, TextField, styled, } from '@mui/material'
import backgroundImage from './../../assets/img/podcast.jpg'
import backgroundImage1 from './../../assets/img/7.webp'
import { CheckSharp, Email, } from '@mui/icons-material';
import ImageBackground from './ImageBackground';

const EmailField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#ffffff80',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'gray',
        borderColor: '##ffffff80'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'gray',
        },
        '&:hover fieldset': {
            borderColor: '#ffffff80',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ffffff80',
        },
    },
    '& .MuiInputBase-input': {
        color: 'white',
        fontSize: '20px'
    },
    '& .MuiTextField-root': {
        borderColor: '#ffffff80'
    }
});

function NewsLetterSignup() {
    return (
        <ImageBackground image_1={backgroundImage} image_2={backgroundImage1}>
            <Box
                sx={{ width: { xs: '100%', sm: '100%', md: '60%', lg: '60%' }, height: { xs: '100%', sm: '100%', md: '40%', lg: '40%' }, backgroundColor: '#00000080', borderRadius: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
                <Typography variant='h5' sx={{ marginBottom: 3 }}>Sign Up For Newsletter</Typography>
                <Typography variant='body2' sx={{ marginBottom: 2, fontSize: 16, marginLeft: { xs: 3, sm: 3 }, marginRight: { xs: 3, sm: 3 }, textAlign: 'center' }}>Every 7 days you will get a list of all the podcasts and videos in the last week.</Typography>
                <EmailField
                    id="custom-css-outlined-input"
                    sx={{ background: '#00000080', borderColor: '#ffffff80', width: { xs: '80%', sm: '80%', md: '40%', lg: '40%' }, marginBottom: 2 }}
                    placeholder={'Email'}
                    inputMode={'email'}
                />
                <Button variant='outlined' sx={{ fontSize: 16, color: 'white', borderColor: 'white' }} startIcon={<CheckSharp />} >Sign Up</Button>
            </Box>
        </ImageBackground>
    )
}

export default NewsLetterSignup