import axios from "axios";

// This function makes an asynchronous POST request to server-side with URL and data as arguments.
const post = async (url, data) => {
    try {    
        // `await` expression is used for resolving a promise. 
        // In this case, `axios.post()` is used to send POST request to server and the response object is returned.        
        const response = await axios.post(url, data);
        
        // Response object is returned once the successful response is received.
        return response;
    } catch (error) {
        // If any error occurred during the process, then `catch` block will handle it and the error will be returned.
        return error;
    }
}


const get = async (url) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error(error);
    throw new Error("Unable to complete GET request");
  }
};


export {post, get}