import React, { useState } from "react";
import { AppBar, Box, Toolbar, Typography, styled, alpha, InputBase, IconButton, Button } from "@mui/material";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import SearchIcon from '@mui/icons-material/Search';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from "react-router-dom";
import store from "../../store";
import { get, post } from "../../apis";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function Header() {

  const [artcles, setArticles] = store.useState('articles');
  const [isLoading, setIsLoading] = store.useState('articlesLoading');

  const navItems = [{ name: 'Add Podcast', link: 'add' }, { name: 'Newsletter SignUp', link: 'signup' }, { name: 'About', link: 'about' }]
  const [display, setDisplay] = useState({ xs: 'none', sm: 'none', md: 'none', lg: 'none' });
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(document.body.offsetHeight);
  const [search, setSearh] = useState('');

  const handleResize = () => {
    const height = document.body.offsetHeight;
    setBodyHeight(height);
  };

  const searchHandler = (event) => {
    setSearh(event.target.value);
    const data = event.target.value;

    let formdata = new FormData();
    formdata.append("search_char", data);
    
    if (event.target.value.length > 2) fetchDataSearch(formdata);
    else fetchDataForm();
  };

  const fetchDataSearch = async (formdata) => {
    setIsLoading(true);
    try {
      const res = await post("/api/search", formdata);
      if (res.status === 200 && res.data !== undefined) {
        setArticles(res.data);
        setIsLoading(false);
      };
    } catch (error) {
      console.log(error)
    }
  };

  const fetchDataForm = async () => {
    try {
      setIsLoading(true);
      const res = await get("/api/home/articles");
      if (res.status === 200 && res.data !== undefined) {
        setArticles(res.data);
        setIsLoading(false);
      };
    } catch (error) {
      console.log(error)
    }
  };

  window.addEventListener('resize', handleResize);

  const searchView = () => {
    setShowSearchInput(!showSearchInput)
    setBodyHeight(document.body.offsetHeight);
    if (!showSearchInput) setDisplay({ ...display, xs: 'block', sm: 'block' })
    else setDisplay({ ...display, xs: 'none', sm: 'none' })
  };

  return (
    <Box>
      <AppBar position="fixed" style={{ backgroundColor: '#303030' }}>
        <Toolbar>
          <Link to='/' style={{ flex: 2, color: 'white' }}>
            <Typography variant="h6" component={'div'} style={{ flex: 2, color: 'white' }} noWrap >Insurance Agency Podcasts</Typography>
          </Link>
          <IconButton sx={{ display: { xs: "blcok", sm: 'blcok', md: 'none', lg: 'none' } }} onClick={searchView} color='inherit'>
            <SearchIcon />
          </IconButton>
          <Box flexDirection={"row"} display={'flex'} justifyContent='center' alignItems={'center'} sx={{ display: { xs: "none", sm: 'flex', md: 'flex', lg: 'flex' } }}>
            <Search sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                value={search}
                onChange={searchHandler}
              />
            </Search>
            <Box display={'flex'} flexDirection='row'>
              {navItems.map((item, index) => (
                <React.Fragment key={item['name']}>
                  <Link to={'/' + item['link']} style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton size={"large"} color="inherit">
                      {index === 0 ? <PodcastsIcon /> : <></>}
                      {index === 1 ? <NewspaperRoundedIcon /> : <></>}
                      {index === 2 ? <InfoIcon /> : <></>}
                      <Typography padding={1}>{item['name']}</Typography>
                    </IconButton>
                  </Link>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Toolbar>
        <Toolbar sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
          <Box display={'flex'} flexDirection='row' sx={{justifyContent: 'center', alignItems: 'center'}}>
            {navItems.map((item, index) => (
              <React.Fragment key={item['link']}>
                <Link to={'/' + item['link']} style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button size={"large"} color="inherit">
                    {index === 0 ? <PodcastsIcon /> : <></>}
                    {index === 1 ? <NewspaperRoundedIcon /> : <></>}
                    {index === 2 ? <InfoIcon /> : <></>}
                    <Typography padding={1} fontSize={12}>{item['name']}</Typography>
                  </Button>
                </Link>
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
        {showSearchInput && (<Toolbar sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              value={search}
              onChange={searchHandler}
            />
          </Search>
        </Toolbar>)}
      </AppBar>
      <Box sx={{ display: display, position: 'absolute', height: bodyHeight, width: '100vw', opacity: 0.75, backgroundColor: 'black', overflow: 'hidden', zIndex: 1 }} onClick={searchView} />
    </Box>
  );
}

export default Header;