import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import store from '../../store';
import { post } from './../../apis/index';

function CardSlider({title, number_episodes, recent_episode, wallpaper}) {

    const [articles, setArticles] = store.useState('articles');

    let date = new Date(recent_episode);
    let formattedDate = date.toLocaleDateString() + ', ' + date.toLocaleTimeString();

    const findChannel = async (title) => {
        let formdata = new FormData();
        formdata.append("channel", title);
        const res = await post('./api/findchannel', formdata);

        if(res.status === 200 || res.data !== undefined) setArticles(res.data);
    }
    return (
        <Card sx={{ minWidth: {xs: '80vw', sm: '80vw', md: 345, lg: 345}, height: 150, backgroundColor: 'rgb(30, 30, 30)', backgroundImage: `url(${wallpaper})`, margin: '20px', backgroundSize: '100%', display: 'flex' }}>
            <CardActionArea sx={{display: 'flex', alignItems: 'end', justifyContent: 'flex-start', backgroundColor: '#0000009e'}} onClick={()=>findChannel(title)}>
                <CardContent>
                    <Typography gutterBottom variant="h7" component="div" color={'white'} fontStyle="initial">
                        channel:<span style={{ fontWeight: 'bold', fontFamily: 'revert' }}> {` ${title}`}</span>
                    </Typography>
                    <Typography variant="body2" color="white" >
                        Last Episode Date:<span style={{ fontWeight: 'bold', fontFamily: 'revert' }}> {` ${formattedDate}`}</span> 
                    </Typography>
                    <Typography variant="body2" color="white" >
                        Number Of Episodes:<span style={{ fontWeight: 'bold', fontFamily: 'revert' }}> {` ${number_episodes}`}</span> 
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default CardSlider