import React from 'react'
import { Box } from '@mui/material'

function ImageBackground({ image_1, image_2, children }) {
    const desktopSize = {
        backgroundImage: { sm: `url(${image_1})`, xs: `url(${image_1})`, md: `url(${image_2})`, lg: `url(${image_2})` },
        backgroundSize: 'cover',
        backgroundPosition: { sm: 'top', xs: 'top', md: 'cover', lg: 'cover' },
        width: '100%',
        height: { lg: 'calc(100vh - 50px)', md: 'calc(100vh - 50px)', sm: 'calc(100vh - 100px)', xs: 'calc(100vh - 50px)' },
        backgroundBlendMode: 'difference',
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <Box sx={desktopSize}>
            <Box width={'100vw'} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} sx={{ background: '#000000a0' }}>
                {children}
            </Box>
        </Box>
    )
}

export default ImageBackground