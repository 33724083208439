import { createStore } from "state-pool";

const store = createStore();

store.setState('channels', [])

store.setState('articles', [])

store.setState('channelsLoading', true)

store.setState('articlesLoading', true)

export default store;