import { Box } from '@mui/material'
import React from 'react'
import AboutContent from '../components/homeContent/AboutContent'

function About() {
  return (
    <Box sx={{ paddingTop: { lg: '50px', xs: '120px', sm: '100px', md: '50px' } }}>
        <AboutContent />
    </Box>
  )
}

export default About