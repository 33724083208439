import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import CardSlider from '../channel/CardSlider'
import { v4 as uuidv4 } from 'uuid';
import store from '../../store';
import { get } from './../../apis/index'
import Loading from '../loading/Loading';
import m_back from './../../assets/img/1.webp';
import m_back_1 from './../../assets/img/2.jpg';
import m_back_2 from './../../assets/img/3.jpg';
import m_back_3 from './../../assets/img/4.jpeg';
import m_back_4 from './../../assets/img/5.jpeg';
import m_back_5 from './../../assets/img/6.jpg';

function Slider() {
    const wallpaper = [m_back, m_back_1, m_back_2, m_back_3, m_back_4, m_back_5];
    const [channels, setChannels] = store.useState("channels");
    const [isLoading, setIsLoading] = store.useState("channelsLoading");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await get("/api/home/channels");
                if (res.status === 200) {
                    setChannels(res.data);
                    setIsLoading(!isLoading);
                }
            } catch (error) {
                console.log(error);
            }
        };
        if (isLoading){
            fetchData();
        }
    }, []);

    return (
        <>
            <Box sx={{ marginLeft: { lg: 20, md: 20, sm: 0, xs: 0 }, marginRight: { lg: 20, md: 20, sm: 0, xs: 0 }, marginTop: '10px', display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }, flexDirection: 'row', overflowWrap: 'normal', overflowX: 'auto'}}>
                {
                    channels.map((channel) => (
                        <CardSlider
                            key={uuidv4()} 
                            title={channel['title']} 
                            recent_episode={channel['recent_episode']} 
                            number_episodes={channel['number_episodes']} 
                            wallpaper={wallpaper[Math.floor(Math.random() * wallpaper.length)]} 
                        />
                    ))
                }
            </Box>
            <Box sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' }, flexDirection: 'row', justifyContent: 'center', overflow: 'scroll' }}>
                {
                    channels.map((channel) => (
                        <CardSlider 
                            key={uuidv4()} 
                            title={channel['title']} 
                            recent_episode={channel['recent_episode']} 
                            number_episodes={channel['number_episodes']} 
                            wallpaper={wallpaper[Math.floor(Math.random() * wallpaper.length)]} 
                        />
                    ))
                }
            </Box>
        </>
    )
}

export default Slider